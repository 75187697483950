.inputTable {
    border-collapse: separate;
}
.inputTable td, .inputTable th{
    border-radius: 5px;
    height: 53px;
    vertical-align: middle;
}
.inputTable input {
    border: none;
    text-align: center;
    background-color: transparent;
    padding: 0px;
}
.inputTable input:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}
.inputTable thead th {
    background-color: #2f90ea
}
.inputTable thead td {
    background-color: #989898
}
.inputTable tbody td {
    background-color: #c4c4c4
}
/* Chrome, Safari, Edge, Opera */
.inputTable input::-webkit-outer-spin-button,
.inputTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputTable input[type=number] {
  -moz-appearance: textfield;
}
.fuelCheck input {
    border: 1px solid;
}

.fuelCheck .form-check-input:checked {
    background-color: initial;
    border-color: initial;
}

/* h1 {
    margin-bottom: 40px !important;
} */

/* .projectStepContainer {
    padding: 30px;
} */

.projectStepContainer .input-group-text {
    background-color: transparent !important;
    border: transparent !important;
    color: gray
}
.inputDescNoLink {
    font-style: italic;
    font-size: 12px;
}
.inputDesc {
    font-style: italic;
    font-size: 12px;
    cursor: pointer
}
.inputDesc:hover {
    text-decoration: underline;
}
.emojiChoice {
    margin-left: 5px;
    font-size: 20px;
    cursor: pointer;
}

/* .recharts-legend-item-text {
    color: black !important
} */

.form-switch {
    white-space: nowrap;
}
h2 .badge {
    font-size: 12px;
    vertical-align: middle;
}